@use "../../utils/variables/colors" as c;
@use "../../utils/variables/mixins" as m;

nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 127px;
  background-color: c.$third_grey;
  position: sticky;
  top: 0;
  z-index: 100;
  box-shadow: 0px 0px 80px rgba(0, 0, 0, 0.05);

  // @include m.dashedBorder('bottom');
  & .navContainer {
    display: flex;
    width: 1280px;
    margin: auto;
    height: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    @include m.dashedBorder("left_right");
    @media screen and (max-width: 1275px) {
      width: 100%;
    }
    & .navLink p {
      border-bottom: 1px transparent;
    }
    & .active p {
      border-bottom: 1px solid;
    }
    &__logoContainer {
      margin-left: 100px;
      @media screen and (max-width: 1275px) {
        margin-left: 30px;
        font-size: 0.8rem;
      }
    }
    & img {
      height: 28px;
      margin-right: 12px;
    }
    & a {
      text-decoration: none;
      display: flex;
      flex-direction: row;
      align-items: center;
      & p {
        margin-right: 1rem;
      }
    }
    & ul {
      display: flex;
      flex-direction: row;
      list-style: none;
      align-items: center;
      margin-right: 100px;
      @media screen and (max-width: 1275px) {
        margin-right: 35px;
        font-size: 1rem;
      }
      & li {
        margin-left: 4rem;
        @media screen and (max-width: 1275px) {
          margin-left: 1rem;
        }
        & p {
          text-decoration: none;
          transition: all 0.3s ease;
          &:hover {
            border-bottom: 1px solid;
          }
        }
      }
    }
  }
}

#navName {
  @media screen and (max-width: 1275px) {
    width: 80px;
  }
}

#navJob {
  @media screen and (max-width: 1275px) {
    width: 170px;
  }
}

#navDesktop {
  @media screen and (max-width: 767px) {
    display: none;
  }
}

#navMobile {
  display: none;
  @media screen and (max-width: 767px) {
    display: flex;
    height: 0;
    width: 0;
    overflow: hidden;
  }
}

.mobileNavContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(c.$white, $alpha: 0);
  z-index: 100;
  color: c.$dark;
  overflow: hidden;
  transform: translateX(calc(100% - 96px));
  transition: transform 0.3s ease-in-out;
  &__logoButton {
    padding: 15px 15px 7px 15px;
    border-radius: 0 0 0 10px;
    background-color: c.$white;
    box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.18);
    &--logo {
      height: 42px;
    }
  }
  &__navBar {
    background-color: c.$white;
    width: calc(100% - 96px);
    padding: 35px 48px 48px 48px;
    height: 100vh;
    box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.15);
    & ul {
      list-style-type: none;
      margin-top: 20px;
      @include m.dashedBorder("top");
      & li {
        padding-top: 40px;
        display: flex;
      }
    }
    &__name {
      font-size: 0.9rem;
    }
  }
}

#navOpen {
  transform: translateX(0);
}

#navClose {
  height: 90px;
}

.fadeIn {
  animation: fadeIn 0.3s ease-in-out forwards;
  height: 42px;
}

.fadeOut {
  animation: fadeOut 0.3s ease-in-out forwards;
  height: 42px;
}

.fadeOutAndIn {
  animation: fadeOutAndIn 0.3s ease-in-out forwards;
}

.scaleOutAndIn {
  height: 42px;
  animation: scaleOutAndIn 0.3s ease-in-out forwards;
}

@keyframes fadeOutAndIn {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes scaleOutAndIn {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
