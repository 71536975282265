.loader {
  width: 20%;
  text-align: center;
  padding: 1em;
  display: flex;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  /*
    Set the color of the icon
  */
}
.loader svg path,
.loader svg rect {
  fill: #1d7fd9;
}