@use "../../utils/variables/colors" as c;
@use "../../utils/variables/mixins" as m;

.AboutMeContent {
  @include m.dashedBorder("bottom");
  width: 100%;
  & .lastContainer {
    padding-bottom: 130px;
    @include m.dashedBorder("left_right_bottom");
    @media  screen and (max-width: 767px){
      padding-bottom: 80px;
    }
  }
  &__container {
    width: 1280px;
    margin: 0 auto;
    @include m.dashedBorder("left_right");
    background-color: c.$white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 104px;
    padding-right: 76px;
    padding-bottom: 60px;
    gap: 86px;
    @media screen and (max-width: 1275px) {
      width: 100%;
      flex-direction: column;
    }
    @media screen and (max-width: 767px) {
      padding: 50px 30px 0px;
      gap: 50px;
    }
    &__picture {
      width: 421px;
      height: 278px;
      mask-image: url(../../assets/svg/curly_bracket_mask.svg);
      mask-size: cover;
      @media screen and (max-width: 767px) {
        width: 100%;
        height: 100%;
        z-index: 4;
      }
    }
    & h1 {
      font-family: "Fira sans";
      font-size: 86px;
      font-weight: 700;
      display: flex;
      flex-direction: row;
      margin-top: 94px;
      @media screen and (max-width: 1275px) {
        margin-top: 30px;
      }
      @media screen and (max-width: 767px) {
        font-size: 50px;
        margin-top: 0;
      }
      & span {
        font-size: 86px;
        font-weight: 700;
        color: c.$blue;
        @media screen and (max-width: 767px) {
          font-size: 50px;
        }
      }
    }
    & h2 {
      font-size: 1.1rem;
      font-weight: 700;
      text-transform: uppercase;
      margin-bottom: 18px;
      @media screen and (max-width: 767px) {
        font-size: 1.5rem;
        font-family: "Fira sans";
        line-height: 2rem;
      }
      & span {
        font-weight: 400;
      }
    }
  }
}

.mobileColumnReverse {
  @media screen and (max-width: 1275px) {
    flex-direction: column-reverse;
  }
}
