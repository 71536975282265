@use "../../utils/variables/colors" as c;
@use "../../utils/variables/mixins" as m;

// .toolTip is defined in App.scss

.contactSection {
  @include m.dashedBorder("bottom");
  width: 100%;
  &__container {
    width: 1280px;
    margin: 0 auto;
    @include m.dashedBorder("left_right");
    background-color: c.$third_grey;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-left: 104px;
    padding-top: 63px;
    padding-right: 104px;
    padding-bottom: 80px;
    gap: 44px;
    @media screen and (max-width: 1275px) {
      width: 100%;
    }
    @media screen and (max-width: 767px) {
      padding: 70px 30px;
      gap: 0px;
    }
    & h2 {
      font-family: "Fira mono";
      font-size: 21px;
      font-weight: 700;
      display: flex;
      flex-direction: row;
      text-transform: uppercase;
      & span {
        font-size: 21px;
        font-weight: 400;
        color: c.$blue;
        margin-left: 17px;
      }
    }
    &__mailAndForm {
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin-top: 30px;
      &--mail {
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: center;
        & div {
          margin: auto;
          display: flex;
          flex-direction: row;
        }
        & img {
          width: 19px;
          margin-left: 20px;
        }
        & #contactMail {
          text-decoration: underline;
          color: c.$green;
        }
      }
      &--form {
        width: 50%;
        align-items: flex-end;
        @media screen and (max-width: 1275px) {
          width: 80%;
        }
        & p {
          margin-bottom: 20px;
        }
        & form {
          display: block;
          flex-direction: column;
          // gap: 10px;
          & input {
            width: 100%;
            height: 40px;
            padding-left: 10px;
            border-radius: 5px;
            border: 1px solid c.$second_grey;
            margin-bottom: 15px;
            &::placeholder {
              color: c.$second_grey;
            }
          }
          & label {
            font-size: 14px;
            margin-bottom: 10px;
          }
          & textarea {
            width: 100%;
            height: 150px;
            padding: 10px 10px;

            border: 1px solid c.$second_grey;
            border-radius: 5px;
            margin-bottom: 15px;
            &::placeholder {
              color: c.$second_grey;
            }
          }
        }
      }
    }
  }
}
