.singleTitle {
  width: 100%;
  background-color: #fdfdfd;
}
.singleTitle__container {
  width: 1280px;
  margin: 0 auto;
  border-image-width: 1px;
  border-image-slice: 1;
  border-image-repeat: round;
  border-image-source: url(../../assets/images/border_left_right.png);
  background-color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  padding-left: 104px;
  padding-top: 94px;
  padding-right: 104px;
  padding-bottom: 80px;
  gap: 86px;
}
@media screen and (max-width: 1275px) {
  .singleTitle__container {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
  }
}
@media screen and (max-width: 767px) {
  .singleTitle__container {
    padding: 50px 30px;
  }
}
.singleTitle__container__projectLinks {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
}
@media screen and (max-width: 1275px) {
  .singleTitle__container__projectLinks {
    flex-direction: row;
    gap: 20px;
    margin-top: 40px;
    margin-bottom: 0;
    padding-left: 0;
    padding-bottom: 30px;
    border-image-width: 1px;
    border-image-slice: 1;
    border-image-repeat: round;
    border-image-source: url(../../assets/images/border_bottom.png);
  }
}
@media screen and (max-width: 767px) {
  .singleTitle__container__projectLinks {
    flex-direction: column;
  }
}
.singleTitle__container__txt {
  border-image-width: 1px;
  border-image-slice: 1;
  border-image-repeat: round;
  border-image-source: url(../../assets/images/border_bottom.png);
  width: 100%;
  padding-bottom: 55px;
}
.singleTitle__container__txt--tags span {
  font-family: "Fira sans";
  text-decoration: underline;
  margin-right: 20px;
}
.singleTitle__container__txt--arrow {
  transform: scale(1) rotate(180deg);
  transition: all 0.3s ease;
  cursor: pointer;
}
.singleTitle__container__txt--arrow:hover {
  transform: scale(1.3) rotate(180deg);
}
.singleTitle__container p {
  width: 70%;
  margin-bottom: 20px;
}
@media screen and (max-width: 767px) {
  .singleTitle__container p {
    width: 100%;
  }
}
.singleTitle__container h1 {
  font-family: "Fira sans";
  font-size: 86px;
  font-weight: 700;
  display: flex;
  flex-direction: row;
}
@media screen and (max-width: 767px) {
  .singleTitle__container h1 {
    line-height: normal;
    display: block;
    font-size: 50px;
    margin-top: 20px;
  }
}
.singleTitle__container h1 span {
  font-size: 86px;
  font-weight: 700;
  color: #1d7fd9;
}
@media screen and (max-width: 767px) {
  .singleTitle__container h1 span {
    font-size: 50px;
  }
}