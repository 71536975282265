.hero {
  border-image-width: 1px;
  border-image-slice: 1;
  border-image-repeat: round;
  border-image-source: url(../../assets/images/border_bottom.png);
  width: 100%;
}
.hero__container {
  width: 1280px;
  margin: 0 auto;
  border-image-width: 1px;
  border-image-slice: 1;
  border-image-repeat: round;
  border-image-source: url(../../assets/images/border_left_right_bottom.png);
  background-color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 104px;
  padding-top: 94px;
  padding-right: 76px;
  padding-bottom: 130px;
  gap: 86px;
}
@media screen and (max-width: 1275px) {
  .hero__container {
    width: 100%;
    flex-direction: column;
  }
}
@media screen and (max-width: 767px) {
  .hero__container {
    padding: 10px 30px;
    gap: 40px;
  }
}
.hero__container__picture {
  width: 421px;
  height: 278px;
  mask-image: url(../../assets/svg/curly_bracket_mask.svg);
  mask-size: cover;
}
@media screen and (max-width: 767px) {
  .hero__container__picture {
    width: 100%;
    height: 100%;
    margin-bottom: 40px;
  }
}
.hero__container h1 {
  font-family: "Fira sans";
  font-size: 86px;
  font-weight: 700;
  display: flex;
  flex-direction: row;
}
@media screen and (max-width: 767px) {
  .hero__container h1 {
    font-size: 50px;
  }
}
.hero__container h1 span {
  font-size: 86px;
  font-weight: 700;
  color: #1d7fd9;
}
@media screen and (max-width: 767px) {
  .hero__container h1 span {
    font-size: 50px;
  }
}