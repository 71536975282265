.button, .projectLinks--githubPages, .projectLinks--github, .projectLinks--figma, .paginationButton, .sendButton, .contactButton {
  padding: 10px 25px;
  font-size: 1.1rem;
  border-radius: 5px;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.3s ease;
  border: 1px solid transparent;
}
.button:hover, .projectLinks--githubPages:hover, .projectLinks--github:hover, .projectLinks--figma:hover, .paginationButton:hover, .sendButton:hover, .contactButton:hover {
  background-color: #fff;
  color: #292D30;
  border: 1px solid #dcdcdc;
}

.contactButton {
  background-color: #292D30;
  color: #fff;
}

.sendButton {
  background-color: #0b7764;
  color: #fff;
  display: inline-block;
  font-family: "Fira Mono", monospace;
  font-weight: 400;
  font-style: normal;
}

.paginationButton {
  background-color: #0b7764;
  display: flex;
  color: #fff;
}
.paginationButton:hover {
  background-color: rgba(11, 119, 100, 0.7);
}

.invisibleButton {
  visibility: hidden;
  z-index: -10;
}

.responsiveButton {
  display: none;
}
@media screen and (max-width: 1275px) {
  .responsiveButton {
    display: flex;
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
    background-color: #fff;
    box-shadow: 0.5px 0.5px 2.4px rgba(0, 0, 0, 0.11), 4px 4px 19px rgba(0, 0, 0, 0.22);
  }
}
@media screen and (max-width: 767px) {
  .responsiveButton {
    white-space: nowrap;
  }
}

@media screen and (max-width: 1275px) {
  .graphicMobileButton {
    color: #fff;
    background-color: #ae582b;
  }
}

@media screen and (max-width: 1275px) {
  .uxuiMobileButton {
    color: #fff;
    background-color: #1d7fd9;
  }
}

@media screen and (max-width: 1275px) {
  .frontendMobileButton {
    color: #fff;
    background-color: #b4b005;
  }
}

.projectLinks--figma {
  background-color: #ae582b;
  color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
  width: max-content;
}
.projectLinks--figma img {
  width: 20px;
  height: 20px;
  margin-left: 10px;
}
.projectLinks--figma:hover {
  background-color: rgba(174, 88, 43, 0.8);
  color: #fff;
}

.projectLinks--github {
  background-color: #1d7fd9;
  color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
  width: max-content;
}
.projectLinks--github img {
  width: 20px;
  height: 20px;
  margin-left: 10px;
  filter: brightness(0) invert(1);
}
.projectLinks--github:hover {
  background-color: rgba(29, 127, 217, 0.8);
  color: #fff;
}

.projectLinks--githubPages {
  background-color: #0b7764;
  color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
  white-space: nowrap;
  width: max-content;
}
.projectLinks--githubPages img {
  width: 20px;
  height: 20px;
  margin-left: 10px;
  filter: brightness(0) invert(1);
}
.projectLinks--githubPages:hover {
  background-color: rgba(11, 119, 100, 0.8);
  color: #fff;
}