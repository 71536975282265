@use "../../utils/variables/colors" as c;
@use "../../utils/variables/mixins" as m;

.viewerOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.viewerImage {
  border-radius: 10px;
  max-width: 90vw;
  max-height: 90vh;
  object-fit: contain;
}

.viewerButton--left {
  position: absolute;
  left: 3.2rem;
  top: 50%;
  z-index: 1001;
  @media screen and (max-width: 1275px){
    top: 94%;
  }
  & img {
    transform: rotate(180deg) scale(2.5);
    filter: brightness(0.5) invert(1);
  }
}
.viewerButton--right {
  position: absolute;
  right: 3.2rem;
  top: 50%;
  z-index: 1001;
  @media screen and (max-width: 1275px){
    top: 94%;
  }
  & img {
    transform: scale(2.5);
    filter: brightness(0.5) invert(1);
  }
}
.viewerButton--close {
  position: absolute;
  top: 40px;
  right: 40px;
  z-index: 1001;

  & img {
    width: 50px;
    filter: brightness(0.9);
  }
}

.viewerGallery {
  column-count: 4;
  column-gap: 25px;
  box-sizing: border-box;
  break-inside: avoid;
  @media screen and (max-width: 1275px) {
    column-count: 2;
  }
}


.viewerCounter {
    position: absolute;
    bottom: 15px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1001;
    color: c.$white;
    font-size: 1rem;
    font-weight: 400;
    font-family: "Fira mono";
}

.galleryImg {
  width: 100%;
  height: 100%;
  margin-bottom: 25px;
  object-fit: cover;
  border-radius: 10px;
  box-shadow: 2.7px 3.1px 5.3px rgba(0, 0, 0, 0.024),
    9.2px 10.3px 17.9px rgba(0, 0, 0, 0.036), 41px 46px 80px rgba(0, 0, 0, 0.06);
  cursor: pointer;
  transform: scale(1);
  transition: transform 0.3s ease;
  &:hover {
    transform: scale(1.05);
  }
}