.AboutMeContent {
  border-image-width: 1px;
  border-image-slice: 1;
  border-image-repeat: round;
  border-image-source: url(../../assets/images/border_bottom.png);
  width: 100%;
}
.AboutMeContent .lastContainer {
  padding-bottom: 130px;
  border-image-width: 1px;
  border-image-slice: 1;
  border-image-repeat: round;
  border-image-source: url(../../assets/images/border_left_right_bottom.png);
}
@media screen and (max-width: 767px) {
  .AboutMeContent .lastContainer {
    padding-bottom: 80px;
  }
}
.AboutMeContent__container {
  width: 1280px;
  margin: 0 auto;
  border-image-width: 1px;
  border-image-slice: 1;
  border-image-repeat: round;
  border-image-source: url(../../assets/images/border_left_right.png);
  background-color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 104px;
  padding-right: 76px;
  padding-bottom: 60px;
  gap: 86px;
}
@media screen and (max-width: 1275px) {
  .AboutMeContent__container {
    width: 100%;
    flex-direction: column;
  }
}
@media screen and (max-width: 767px) {
  .AboutMeContent__container {
    padding: 50px 30px 0px;
    gap: 50px;
  }
}
.AboutMeContent__container__picture {
  width: 421px;
  height: 278px;
  mask-image: url(../../assets/svg/curly_bracket_mask.svg);
  mask-size: cover;
}
@media screen and (max-width: 767px) {
  .AboutMeContent__container__picture {
    width: 100%;
    height: 100%;
    z-index: 4;
  }
}
.AboutMeContent__container h1 {
  font-family: "Fira sans";
  font-size: 86px;
  font-weight: 700;
  display: flex;
  flex-direction: row;
  margin-top: 94px;
}
@media screen and (max-width: 1275px) {
  .AboutMeContent__container h1 {
    margin-top: 30px;
  }
}
@media screen and (max-width: 767px) {
  .AboutMeContent__container h1 {
    font-size: 50px;
    margin-top: 0;
  }
}
.AboutMeContent__container h1 span {
  font-size: 86px;
  font-weight: 700;
  color: #1d7fd9;
}
@media screen and (max-width: 767px) {
  .AboutMeContent__container h1 span {
    font-size: 50px;
  }
}
.AboutMeContent__container h2 {
  font-size: 1.1rem;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 18px;
}
@media screen and (max-width: 767px) {
  .AboutMeContent__container h2 {
    font-size: 1.5rem;
    font-family: "Fira sans";
    line-height: 2rem;
  }
}
.AboutMeContent__container h2 span {
  font-weight: 400;
}

@media screen and (max-width: 1275px) {
  .mobileColumnReverse {
    flex-direction: column-reverse;
  }
}