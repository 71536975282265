nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 127px;
  background-color: #fdfdfd;
  position: sticky;
  top: 0;
  z-index: 100;
  box-shadow: 0px 0px 80px rgba(0, 0, 0, 0.05);
}
nav .navContainer {
  display: flex;
  width: 1280px;
  margin: auto;
  height: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-image-width: 1px;
  border-image-slice: 1;
  border-image-repeat: round;
  border-image-source: url(../../assets/images/border_left_right.png);
}
@media screen and (max-width: 1275px) {
  nav .navContainer {
    width: 100%;
  }
}
nav .navContainer .navLink p {
  border-bottom: 1px transparent;
}
nav .navContainer .active p {
  border-bottom: 1px solid;
}
nav .navContainer__logoContainer {
  margin-left: 100px;
}
@media screen and (max-width: 1275px) {
  nav .navContainer__logoContainer {
    margin-left: 30px;
    font-size: 0.8rem;
  }
}
nav .navContainer img {
  height: 28px;
  margin-right: 12px;
}
nav .navContainer a {
  text-decoration: none;
  display: flex;
  flex-direction: row;
  align-items: center;
}
nav .navContainer a p {
  margin-right: 1rem;
}
nav .navContainer ul {
  display: flex;
  flex-direction: row;
  list-style: none;
  align-items: center;
  margin-right: 100px;
}
@media screen and (max-width: 1275px) {
  nav .navContainer ul {
    margin-right: 35px;
    font-size: 1rem;
  }
}
nav .navContainer ul li {
  margin-left: 4rem;
}
@media screen and (max-width: 1275px) {
  nav .navContainer ul li {
    margin-left: 1rem;
  }
}
nav .navContainer ul li p {
  text-decoration: none;
  transition: all 0.3s ease;
}
nav .navContainer ul li p:hover {
  border-bottom: 1px solid;
}

@media screen and (max-width: 1275px) {
  #navName {
    width: 80px;
  }
}

@media screen and (max-width: 1275px) {
  #navJob {
    width: 170px;
  }
}

@media screen and (max-width: 767px) {
  #navDesktop {
    display: none;
  }
}

#navMobile {
  display: none;
}
@media screen and (max-width: 767px) {
  #navMobile {
    display: flex;
    height: 0;
    width: 0;
    overflow: hidden;
  }
}

.mobileNavContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0);
  z-index: 100;
  color: #292D30;
  overflow: hidden;
  transform: translateX(calc(100% - 96px));
  transition: transform 0.3s ease-in-out;
}
.mobileNavContainer__logoButton {
  padding: 15px 15px 7px 15px;
  border-radius: 0 0 0 10px;
  background-color: #fff;
  box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.18);
}
.mobileNavContainer__logoButton--logo {
  height: 42px;
}
.mobileNavContainer__navBar {
  background-color: #fff;
  width: calc(100% - 96px);
  padding: 35px 48px 48px 48px;
  height: 100vh;
  box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.15);
}
.mobileNavContainer__navBar ul {
  list-style-type: none;
  margin-top: 20px;
  border-image-width: 1px;
  border-image-slice: 1;
  border-image-repeat: round;
  border-image-source: url(../../assets/images/border_top.png);
}
.mobileNavContainer__navBar ul li {
  padding-top: 40px;
  display: flex;
}
.mobileNavContainer__navBar__name {
  font-size: 0.9rem;
}

#navOpen {
  transform: translateX(0);
}

#navClose {
  height: 90px;
}

.fadeIn {
  animation: fadeIn 0.3s ease-in-out forwards;
  height: 42px;
}

.fadeOut {
  animation: fadeOut 0.3s ease-in-out forwards;
  height: 42px;
}

.fadeOutAndIn {
  animation: fadeOutAndIn 0.3s ease-in-out forwards;
}

.scaleOutAndIn {
  height: 42px;
  animation: scaleOutAndIn 0.3s ease-in-out forwards;
}

@keyframes fadeOutAndIn {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes scaleOutAndIn {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}