@use "../../utils/variables/colors" as c;
@use "../../utils/variables/mixins" as m;

.singleTitle {
  width: 100%;
  background-color: c.$third_grey;
  &__container {
    width: 1280px;
    margin: 0 auto;
    @include m.dashedBorder("left_right");
    background-color: c.$white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    padding-left: 104px;
    padding-top: 94px;
    padding-right: 104px;
    padding-bottom: 80px;
    gap: 86px;
    @media screen and (max-width: 1275px) {
      width: 100%;
      flex-direction: column;
      align-items: flex-start;
    }
    @media screen and (max-width: 767px){
      padding: 50px 30px;
      
    }
    &__projectLinks {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      margin-bottom: 50px;
      // padding-left: 20px;
      // @include m.dashedBorder("left");
      @media screen and (max-width: 1275px) {
        flex-direction: row;
        gap: 20px;
        margin-top: 40px;
        margin-bottom: 0;
        padding-left: 0;
        padding-bottom: 30px;
        @include m.dashedBorder("bottom");
      }
      @media screen and (max-width: 767px) {
        flex-direction: column;        
      }

    }
    &__txt {
      @include m.dashedBorder("bottom");
      width: 100%;
      padding-bottom: 55px;
      &--tags {
        & span {
          font-family: "Fira sans";
          text-decoration: underline;
          margin-right: 20px;
        }
      }
      &--arrow {
        transform: scale(1) rotate(180deg);
        transition: all 0.3s ease;
        cursor: pointer;
        &:hover {
          transform: scale(1.3) rotate(180deg);
        }
      }
    }
    & p {
      width: 70%;
      margin-bottom: 20px;
      @media screen and (max-width: 767px){
        width: 100%;
        
      }
    }
    & h1 {
      font-family: "Fira sans";
      font-size: 86px;
      font-weight: 700;
      display: flex;
      flex-direction: row;
      @media screen and (max-width: 767px){
        line-height: normal;
        display: block;
        font-size: 50px;
        margin-top: 20px;
      }
      & span {
        font-size: 86px;
        font-weight: 700;
        color: c.$blue;
        @media screen and (max-width: 767px){
          font-size: 50px;
          
        }
      }
    }
  }
}
