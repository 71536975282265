@use "../../utils/variables/mixins.scss" as m;


.sectionError {
  display: flex;
  width: 100%;
  @include m.dashedBorder("bottom");
 flex-grow: 1;
  &__container {
    @include m.dashedBorder("left_right");
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    flex-grow: 1;
    max-width: 1280px;
    & h1 {
      font-size: 3rem;
      margin-bottom: 1rem;
    }
    & h2 {
      font-size: 2rem;
      margin-bottom: 1rem;
    }
    & a {
      text-decoration: underline;
    }
  }
  &__Container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    width: 100%;
    height: 100%;
    & h1 {
      font-size: 3rem;
      margin-bottom: 1rem;
    }
    & h2 {
      font-size: 2rem;
      margin-bottom: 1rem;
    }
    & a {
      text-decoration: underline;
    }
  }
}
