@use "../../utils/variables/colors" as c;
@use "../../utils/variables/mixins" as m;

.skillsSection {
  @include m.dashedBorder("bottom");
  width: 100%;
  &__container {
    width: 1280px;
    margin: 0 auto;
    @include m.dashedBorder("left_right_bottom");
    background-color: c.$white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-left: 104px;
    padding-top: 63px;
    padding-right: 104px;
    padding-bottom: 80px;
    gap: 44px;
    @media screen and (max-width: 1275px) {
      width: 100%;
      padding-bottom: 50px;
    }
    @media screen and (max-width: 767px){
      padding: 50px 30px 20px;
      
    }
    &__skills {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      @media screen and (max-width: 1275px) {
        flex-direction: column;
      }
      & img {
        width: 20px;
        margin-left: 5px;
      }
      &--skill {
        width: 330px;
        @include m.dashedBorder("left");
        padding-left: 41px;
        @media screen and (max-width: 1275px) {
          @include m.dashedBorder("top");
          padding-top: 20px;
          margin-bottom: 40px;
          width: 100%;
        }
        & h3 {
          font-size: 1.1rem;
          margin-bottom: 10px;
        }
        & ul {
          list-style: none;
          & li {
            display: flex;
            align-items: center;
          }
        }
      }
    }
    & h2 {
      font-family: "Fira mono";
      font-size: 21px;
      font-weight: 700;
      display: flex;
      flex-direction:row;
      flex-wrap: wrap;
      text-transform: uppercase;
      @media screen and (max-width: 767px){
        white-space: nowrap;
      }
      & span {
        font-size: 21px;
        font-weight: 400;
        color: c.$blue;
        margin-left: 17px;
        @media screen and (max-width: 767px){
          margin-left: 0;
          
        }
      }
    }
  }
}

.rotateAnimation {
  animation: rotate 4s linear infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
