.projectCard {
  border: 1px solid #292D30;
  background-color: #fff;
  border-radius: 10px;
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
}
.projectCard__description {
  font-size: 1rem;
  font-weight: 400;
  line-height: 150%;
  color: #292d30;
}
.projectCard__image {
  z-index: 1;
  width: 100%;
  border-radius: 0 0 10px 10px;
  object-fit: cover;
  transition: transform 0.3s ease-in-out;
}
.projectCard:hover .projectCard__image {
  transform: scale(1.1);
}
.projectCard__content {
  padding: 35px;
  display: flex;
  z-index: 2;
  flex-direction: column;
  background-color: #fff;
  border-radius: 10px 10px 0 0;
}
.projectCard__content__titleAndTagsContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 17px;
  width: 100%;
}
.projectCard__content__titleAndTagsContainer:first-child {
  display: flex;
  flex-direction: row;
}
.projectCard__content__titleAndTagsContainer__tags {
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  text-transform: uppercase;
  margin-top: 15px;
}
.projectCard__content__titleAndTagsContainer__tags--tag {
  margin-right: 10px;
}
.projectCard__content__titleAndTagsContainer--title {
  font-size: 1.3rem;
  font-family: "Fira sans", sans-serif;
  font-weight: 700;
  color: #292D30;
  margin-right: 10px;
  text-transform: uppercase;
  overflow: hidden;
  line-height: 1.5;
  position: relative;
  text-overflow: ellipsis;
  width: 100%;
  padding-bottom: 8px;
}
.projectCard__content__titleAndTagsContainer--title::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #dcdcdc;
}
.projectCard__content__titleAndTagsContainer__arrow {
  margin-top: 5px;
  cursor: pointer;
  transform: scale(1);
  transition: all 0.3s ease;
}
.projectCard__content__titleAndTagsContainer__arrow:hover {
  transform: scale(1.1);
}

.titleAndTagsContainerDiv {
  width: 100%;
}