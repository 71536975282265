@use "../../utils/variables/colors" as c;
@use "../../utils/variables/mixins" as m;


* {
  break-inside: avoid;
}

.projectsArchive {
  width: 100%;
  background-color: c.$third_grey;
  @include m.dashedBorder("bottom");
  &__container {
    width: 1280px;
    margin: 0 auto;
    @include m.dashedBorder("left_right");
    background-color: c.$white;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-left: 104px;
    padding-right: 104px;
    padding-bottom: 80px;
    @media screen and (max-width: 1275px) {
      width: 100%;
    }
    @media screen and (max-width: 767px){
      padding: 0px 30px 50px;
      
    }
    &__filters {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      gap: 28px;
      margin-bottom: 80px;
      @media screen and (max-width: 1275px) {
        flex-wrap: wrap;
      }
    }
    &__cards {
      column-count: 3;
      column-gap: 24px;
      margin-bottom: 25px;
      margin-top: 40px;
      display: inline-block;
      width: 100%;
      box-sizing: border-box;
      break-inside: avoid;
      @media screen and (max-width: 1275px) {
        column-count: 1;
      }
    }
  }
}
