// fira-mono

.fira-mono-regular {
    font-family: "Fira Mono", monospace;
    font-weight: 400;
    font-style: normal;
  }
  
  .fira-mono-medium {
    font-family: "Fira Mono", monospace;
    font-weight: 500;
    font-style: normal;
  }
  
  .fira-mono-bold {
    font-family: "Fira Mono", monospace;
    font-weight: 700;
    font-style: normal;
  }
  

// fira-sans

.fira-sans-thin {
    font-family: "Fira Sans", sans-serif;
    font-weight: 100;
    font-style: normal;
  }
  
  .fira-sans-extralight {
    font-family: "Fira Sans", sans-serif;
    font-weight: 200;
    font-style: normal;
  }
  
  .fira-sans-light {
    font-family: "Fira Sans", sans-serif;
    font-weight: 300;
    font-style: normal;
  }
  
  .fira-sans-regular {
    font-family: "Fira Sans", sans-serif;
    font-weight: 400;
    font-style: normal;
  }
  
  .fira-sans-medium {
    font-family: "Fira Sans", sans-serif;
    font-weight: 500;
    font-style: normal;
  }
  
  .fira-sans-semibold {
    font-family: "Fira Sans", sans-serif;
    font-weight: 600;
    font-style: normal;
  }
  
  .fira-sans-bold {
    font-family: "Fira Sans", sans-serif;
    font-weight: 700;
    font-style: normal;
  }
  
  .fira-sans-extrabold {
    font-family: "Fira Sans", sans-serif;
    font-weight: 800;
    font-style: normal;
  }
  
  .fira-sans-black {
    font-family: "Fira Sans", sans-serif;
    font-weight: 900;
    font-style: normal;
  }
  
  .fira-sans-thin-italic {
    font-family: "Fira Sans", sans-serif;
    font-weight: 100;
    font-style: italic;
  }
  
  .fira-sans-extralight-italic {
    font-family: "Fira Sans", sans-serif;
    font-weight: 200;
    font-style: italic;
  }
  
  .fira-sans-light-italic {
    font-family: "Fira Sans", sans-serif;
    font-weight: 300;
    font-style: italic;
  }
  
  .fira-sans-regular-italic {
    font-family: "Fira Sans", sans-serif;
    font-weight: 400;
    font-style: italic;
  }
  
  .fira-sans-medium-italic {
    font-family: "Fira Sans", sans-serif;
    font-weight: 500;
    font-style: italic;
  }
  
  .fira-sans-semibold-italic {
    font-family: "Fira Sans", sans-serif;
    font-weight: 600;
    font-style: italic;
  }
  
  .fira-sans-bold-italic {
    font-family: "Fira Sans", sans-serif;
    font-weight: 700;
    font-style: italic;
  }
  
  .fira-sans-extrabold-italic {
    font-family: "Fira Sans", sans-serif;
    font-weight: 800;
    font-style: italic;
  }
  
  .fira-sans-black-italic {
    font-family: "Fira Sans", sans-serif;
    font-weight: 900;
    font-style: italic;
  }
  