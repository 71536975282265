@use "../../utils/variables/colors" as c;
@use "../../utils/variables/mixins" as m;

.hero {
  @include m.dashedBorder("bottom");
  width: 100%;
  &__container {
    width: 1280px;
    margin: 0 auto;
    @include m.dashedBorder("left_right_bottom");
    background-color: c.$white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 104px;
    padding-top: 94px;
    padding-right: 76px;
    padding-bottom: 130px;
    gap: 86px;
    @media screen and (max-width: 1275px){
      width: 100%;
      flex-direction: column;
    }
    @media screen and (max-width: 767px){
      padding: 10px 30px;
      gap : 40px;      
    }
    &__picture {
      width: 421px;
      height: 278px;
      mask-image: url(../../assets/svg/curly_bracket_mask.svg);
      mask-size: cover;
      @media screen and (max-width: 767px){
        width: 100%;
        height: 100%;
        margin-bottom: 40px;

      }
    }
    & h1 {
      font-family: "Fira sans";
      font-size: 86px;
      font-weight: 700;
      display: flex;
      flex-direction: row;
      @media screen and (max-width: 767px){
        font-size: 50px;
      }
      & span {
        font-size: 86px;
        font-weight: 700;
        color: c.$blue;
        @media screen and (max-width: 767px){
          font-size: 50px;
        }
      }
    }
  }
}
