.projectNavContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;
}

.projectCounter {
  display: flex;
  justify-content: center;
  align-items: center;
}

.rotate180 {
  transform: rotate(180deg);
}

.buttonNavContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
}
@media screen and (max-width: 767px) {
  .buttonNavContainer {
    flex-direction: column;
    align-items: flex-end;
    text-align: end;
    font-size: 0.9rem;
    gap: 10px;
  }
}

@media screen and (max-width: 767px) {
  .buttonNavColumnReverseMobile {
    flex-direction: column-reverse;
    align-items: flex-start;
    text-align: start;
  }
}