@use "../../utils/variables/colors" as c;
@use "../../utils/variables/mixins" as m;

.pageCounterContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  position: relative;
}

.pageCounter {
  display: flex;
  justify-content: center;
  align-items: center;
}

.rotate180 {
  transform: rotate(180deg);
}

.pagePrevious {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.pageNext {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}
