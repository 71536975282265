.projectsIntro {
  width: 100%;
  background-color: #fdfdfd;
}
.projectsIntro__container {
  width: 1280px;
  margin: 0 auto;
  border-image-width: 1px;
  border-image-slice: 1;
  border-image-repeat: round;
  border-image-source: url(../../assets/images/border_left_right.png);
  background-color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 104px;
  padding-top: 94px;
  padding-right: 76px;
  padding-bottom: 80px;
  gap: 86px;
}
@media screen and (max-width: 1275px) {
  .projectsIntro__container {
    width: 100%;
  }
}
@media screen and (max-width: 767px) {
  .projectsIntro__container {
    padding: 50px 30px;
  }
}
.projectsIntro__container p {
  width: 50%;
}
@media screen and (max-width: 1275px) {
  .projectsIntro__container p {
    width: 100%;
    margin-top: 30px;
  }
}
.projectsIntro__container h1 {
  font-family: "Fira sans";
  font-size: 86px;
  font-weight: 700;
  display: flex;
  flex-direction: row;
}
@media screen and (max-width: 1275px) {
  .projectsIntro__container h1 {
    line-height: normal;
    display: block;
  }
}
@media screen and (max-width: 767px) {
  .projectsIntro__container h1 {
    font-size: 50px;
  }
}
.projectsIntro__container h1 span {
  font-size: 86px;
  font-weight: 700;
  color: #1d7fd9;
}
@media screen and (max-width: 767px) {
  .projectsIntro__container h1 span {
    font-size: 50px;
  }
}