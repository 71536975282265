* {
  break-inside: avoid;
}

.projectsArchive {
  width: 100%;
  background-color: #fdfdfd;
  border-image-width: 1px;
  border-image-slice: 1;
  border-image-repeat: round;
  border-image-source: url(../../assets/images/border_bottom.png);
}
.projectsArchive__container {
  width: 1280px;
  margin: 0 auto;
  border-image-width: 1px;
  border-image-slice: 1;
  border-image-repeat: round;
  border-image-source: url(../../assets/images/border_left_right.png);
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 104px;
  padding-right: 104px;
  padding-bottom: 80px;
}
@media screen and (max-width: 1275px) {
  .projectsArchive__container {
    width: 100%;
  }
}
@media screen and (max-width: 767px) {
  .projectsArchive__container {
    padding: 0px 30px 50px;
  }
}
.projectsArchive__container__filters {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 28px;
  margin-bottom: 80px;
}
@media screen and (max-width: 1275px) {
  .projectsArchive__container__filters {
    flex-wrap: wrap;
  }
}
.projectsArchive__container__cards {
  column-count: 3;
  column-gap: 24px;
  margin-bottom: 25px;
  margin-top: 40px;
  display: inline-block;
  width: 100%;
  box-sizing: border-box;
  break-inside: avoid;
}
@media screen and (max-width: 1275px) {
  .projectsArchive__container__cards {
    column-count: 1;
  }
}