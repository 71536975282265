@use "../../utils/variables/colors" as c;
@use "../../utils/variables/mixins" as m;

.projectsSection {
  @include m.dashedBorder("bottom");
  width: 100%;
  &__container {
    width: 1280px;
    margin: 0 auto;
    @include m.dashedBorder("left_right_bottom");
    background-color: c.$third_grey;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-left: 104px;
    padding-top: 63px;
    padding-right: 104px;
    padding-bottom: 80px;
    gap: 44px;
    @media screen and (max-width: 1275px) {
      width: 100%;
    }
    @media screen and (max-width: 767px){
      padding: 50px 30px 20px;
    }
    &__categories {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      @media screen and (max-width: 1275px) {
        flex-direction: column;
      }
      &__category {
        width: 330px;
        transform: scale(1);
        transition: all 0.3s ease;
        &:hover {
          transform: scale(1.03);
        }
        @media screen and (max-width: 1275px) {
          width: 100%;
          margin-bottom: 30px;
        }
        & a {
          @media screen and (max-width: 1275px) {
            display: flex;
            flex-direction: column;
            position: relative;
          }
          & img {
            border-radius: 5px;
            @media screen and (max-width: 1275px) {
              margin-bottom: 20px;
            }
          }
          & p {
            @media screen and (max-width: 1275px) {
              display: none;
            }
          }
        }
        &-graphic p {
          color: c.$orange;
          border-bottom: 2px solid c.$orange;
          display: inline-block;
        }
        &-uxui p {
          color: c.$blue;
          border-bottom: 2px solid c.$blue;
          display: inline-block;
        }
        &-frontend p {
          color: c.$yellow;
          border-bottom: 2px solid c.$yellow;
          display: inline-block;
        }
      }
    }
  }
  &__picture {
    width: 330px;
    height: 222px;
  }
  & h2 {
    font-family: "Fira mono";
    font-size: 21px;
    font-weight: 700;
    display: flex;
    flex-direction: row;
    text-transform: uppercase;
    & span {
      font-size: 21px;
      font-weight: 400;
      color: c.$blue;
      margin-left: 17px;
    }
  }
}
