.singleContent {
  width: 100%;
  background-color: #fdfdfd;
  border-image-width: 1px;
  border-image-slice: 1;
  border-image-repeat: round;
  border-image-source: url(../../assets/images/border_bottom.png);
}
.singleContent__container {
  width: 1280px;
  margin: 0 auto;
  border-image-width: 1px;
  border-image-slice: 1;
  border-image-repeat: round;
  border-image-source: url(../../assets/images/border_left_right.png);
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-left: 104px;
  padding-top: 30px;
  padding-right: 104px;
  padding-bottom: 80px;
  gap: 70px;
}
@media screen and (max-width: 1275px) {
  .singleContent__container {
    width: 100%;
  }
}
@media screen and (max-width: 767px) {
  .singleContent__container {
    padding: 15px 30px;
    gap: 50px;
  }
  .singleContent__container p {
    font-size: 0.9rem;
  }
}
.singleContent__container__gallery {
  margin-top: 60px;
}
.singleContent__container__txt {
  padding-bottom: 55px;
  width: 100%;
}
.singleContent__container__txt--section1 h2 {
  font-family: "Fira sans";
  font-size: 1.8rem;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 42px */
  text-transform: uppercase;
  margin-bottom: 20px;
}
.singleContent__container__txt--section1 h2 span {
  font-weight: 400;
}
.singleContent__container__txt--section1 .twoColumns {
  font-family: "Fira mono";
  column-count: 2;
  column-gap: 38px;
}
@media screen and (max-width: 1275px) {
  .singleContent__container__txt--section1 .twoColumns {
    column-count: 1;
  }
}
.singleContent__container__txt--section2 h2 {
  font-family: "Fira sans";
  font-size: 1.8rem;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 42px */
  text-transform: uppercase;
  margin-bottom: 20px;
}
.singleContent__container__txt--section2 h2 span {
  font-weight: 400;
}

.displayRow {
  display: flex;
  flex-direction: row;
}
@media screen and (max-width: 1275px) {
  .displayRow {
    flex-direction: column;
  }
}
.displayRow img {
  object-fit: cover;
  border-radius: 10px;
  margin-left: 4rem;
}
@media screen and (max-width: 1275px) {
  .displayRow img {
    margin-left: 0;
    margin-top: 70px;
  }
}

.displayColumnReverse {
  display: flex;
  flex-direction: column-reverse;
}
.displayColumnReverse img {
  object-fit: cover;
  border-radius: 10px;
  margin-left: 4rem;
  max-width: 100%;
  margin-left: 0;
}
.displayColumnReverse .singleContent__container__txt--section2 h2 {
  margin-top: 80px;
}
.displayColumnReverse .singleContent__container__txt--section2 p {
  columns: 2;
  column-gap: 38px;
}
@media screen and (max-width: 1275px) {
  .displayColumnReverse .singleContent__container__txt--section2 p {
    columns: 1;
  }
}

.bodyImg {
  max-width: 100%;
  object-fit: cover;
  border-radius: 10px;
  border: 1px dashed rgba(220, 220, 220, 0.6);
}

.bodyImg3 {
  max-width: 45%;
  height: 100%;
}
@media screen and (max-width: 1275px) {
  .bodyImg3 {
    max-width: 100%;
  }
}