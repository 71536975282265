@use "../../utils/variables/colors" as c;
@use "../../utils/variables/mixins" as m;

.singleContent {
  width: 100%;
  background-color: c.$third_grey;
  @include m.dashedBorder("bottom");
  &__container {
    width: 1280px;
    margin: 0 auto;
    @include m.dashedBorder("left_right");
    background-color: c.$white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding-left: 104px;
    padding-top: 30px;
    padding-right: 104px;
    padding-bottom: 80px;
    gap: 70px;
    @media screen and (max-width: 1275px) {
      width: 100%;
    }
    @media screen and (max-width: 767px){
      padding: 15px 30px;
      gap: 50px;
      & p {
        font-size: 0.9rem;
      }
    }
    &__gallery {
      margin-top: 60px;
    }
    &__txt {
      padding-bottom: 55px;
      width: 100%;
      &--section1 {
        & h2 {
          font-family: "Fira sans";
          font-size: 1.8rem;
          font-style: normal;
          font-weight: 700;
          line-height: 150%; /* 42px */
          text-transform: uppercase;
          margin-bottom: 20px;
          & span {
            font-weight: 400;
          }
        }
        & .twoColumns {
          font-family: "Fira mono";
          column-count: 2;
          column-gap: 38px;
          @media screen and (max-width: 1275px){
            column-count: 1;
          }
        }
      }
      &--section2 {
        & h2 {
          font-family: "Fira sans";
          font-size: 1.8rem;
          font-style: normal;
          font-weight: 700;
          line-height: 150%; /* 42px */
          text-transform: uppercase;
          margin-bottom: 20px;
          & span {
            font-weight: 400;
          }
        }
      }
    }
  }
}

.displayRow {
  display: flex;
  flex-direction: row;
  @media screen and (max-width: 1275px){
    flex-direction: column;
  }
  & img {
    object-fit: cover;
    border-radius: 10px;
    margin-left: 4rem;
    @media screen and (max-width: 1275px){
      margin-left: 0;
      margin-top: 70px;
    }
  }
}

.displayColumnReverse {
  display: flex;
  flex-direction: column-reverse;
  & img {
    object-fit: cover;
    border-radius: 10px;
    margin-left: 4rem;
    max-width: 100%;
    margin-left: 0;
  }
  & .singleContent__container__txt--section2 {
    & h2 {
      margin-top: 80px;
    }
    & p {
      columns: 2;
      column-gap: 38px;
      @media screen and (max-width: 1275px){
        columns: 1;
      }
    }    
  }
}



.bodyImg {
  max-width: 100%;
  object-fit: cover;
  border-radius: 10px;
  border: 1px dashed rgba(c.$second_grey, $alpha: 0.6);
}

.bodyImg3 {
  max-width: 45%;
  height: 100%;
  @media screen and (max-width: 1275px){
    max-width: 100%;
  }
}
