@use "./utils/variables/colors" as c;

/* Réinitialisation CSS */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}


body {
  font-family: "Fira Mono", monospace;
  font-size: 1.1rem;
  line-height: 1.6;
  color: c.$dark; // Couleur de texte par défaut
  background-color: c.$third_grey; // Couleur de fond par défaut
  height: 100vh;
  z-index: -12;
  position: relative;

}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  z-index: -12;
  position: relative;
}

.main {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: start;
}

p {
  font-weight: 400;
  hyphens:none;
  @media screen and (max-width: 430px){
    font-size: 0.9rem;
  }

}

section {
  width: 1280px;
  margin: 0 auto;
  background: c.$third_grey;
}

button {
  border: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;
  letter-spacing: 0.2cap;
}

/* Style par défaut pour les liens */
a {
  color: inherit;
  text-decoration: none;
}

.greenSpan {
  color: c.$green;
}

.blueSpan {
  color: c.$blue;
}

.orangeSpan {
  color: c.$orange;
}

.yellowSpan {
  color: c.$yellow;
}

.toolTip {
  background-color: c.$second_grey;
  padding: 10px 15px 10px 15px;
  clip-path: inset(0 0 0 0 round 20px 20px 20px 0);
  z-index: 5;
  &--error {
    background-color: c.$orange;
    color: c.$white;
    padding: 10px 15px 10px 15px;
    clip-path: inset(0 0 0 0 round 20px 20px 20px 0);
    z-index: 5;
    @media screen and (max-width: 430px){
      font-size: 0.8rem;
      min-width: 150px;
    }
  }
  &--about1 {
    background-color: c.$blue;
    color: c.$white;
    padding: 10px 15px 10px 15px;
    clip-path: inset(0 0 0 0 round 0px 20px 20px 20px);
    max-width: 400px;
    margin: 20px;
    z-index: 5;
    @media screen and (max-width: 430px){
      font-size: 0.8rem;
      min-width: 150px;
    }
  }
  &--about2 {
    background-color: c.$orange;
    color: c.$white;
    padding: 10px 15px 10px 15px;
    clip-path: inset(0 0 0 0 round 0px 20px 20px 20px);
    max-width: 400px;
    margin: 20px;
    z-index: 5;
    @media screen and (max-width: 430px){
      font-size: 0.8rem;
      min-width: 150px;
    }
  }
  &--about3 {
    background-color: c.$green;
    color: c.$white;
    padding: 10px 15px 10px 15px;
    clip-path: inset(0 0 0 0 round 0px 20px 20px 20px);
    max-width: 400px;
    margin: 20px;
    z-index: 5;
    @media screen and (max-width: 430px){
      font-size: 0.8rem;
      min-width: 150px;
    }
  }
}



