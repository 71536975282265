@use "../../utils/variables/colors" as c;
@use "../../utils/variables/mixins" as m;

.projectCard {
  border: 1px solid c.$dark;
  background-color: c.$white;
  border-radius: 10px;
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  &__description {
    font-size: 1rem;
    font-weight: 400;
    line-height: 150%;
    color: rgba( c.$dark, $alpha: 1);}
  &__image {
    z-index: 1;
    width: 100%;
    border-radius: 0 0 10px 10px;
    object-fit: cover;
    transition: transform 0.3s ease-in-out; // Ajout de la transition
    // Ajout de l'effet de zoom au survol
    .projectCard:hover & {
      transform: scale(1.1);
    }
  }
  &__content {
    padding: 35px;
    display: flex;
    z-index: 2;
    flex-direction: column;
    background-color: c.$white;
    border-radius: 10px 10px 0 0;
    &__titleAndTagsContainer {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      margin-bottom: 17px;
      width: 100%;
      &:first-child {
        display: flex;
        flex-direction: row;
      }
      &__tags {
        font-size: 1.1rem;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        text-transform: uppercase;
        margin-top: 15px;
        &--tag {
          margin-right: 10px;
        }
      }
      &--title {
        font-size: 1.3rem;
        font-family: "Fira sans", sans-serif;
        font-weight: 700;
        color: c.$dark;
        margin-right: 10px;
        text-transform: uppercase;
        overflow: hidden;
        line-height: 1.5;
        position: relative;
        text-overflow: ellipsis;
        width: 100%;
        padding-bottom: 8px;
        &::after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%; 
          height: 1px;
          background-color: c.$second_grey; 
        }
      }
      &__arrow {
        margin-top: 5px;
        // margin-left: auto;
        cursor: pointer;
        transform: scale(1);
        transition: all 0.3s ease;
        &:hover {
          transform: scale(1.1);
        }
      }
    }
  }
}


.titleAndTagsContainerDiv {
  width: 100%;
}