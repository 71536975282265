@charset "UTF-8";
/* Réinitialisation CSS */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Fira Mono", monospace;
  font-size: 1.1rem;
  line-height: 1.6;
  color: #292D30;
  background-color: #fdfdfd;
  height: 100vh;
  z-index: -12;
  position: relative;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  z-index: -12;
  position: relative;
}

.main {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: start;
}

p {
  font-weight: 400;
  hyphens: none;
}
@media screen and (max-width: 430px) {
  p {
    font-size: 0.9rem;
  }
}

section {
  width: 1280px;
  margin: 0 auto;
  background: #fdfdfd;
}

button {
  border: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;
  letter-spacing: 0.2cap;
}

/* Style par défaut pour les liens */
a {
  color: inherit;
  text-decoration: none;
}

.greenSpan {
  color: #0b7764;
}

.blueSpan {
  color: #1d7fd9;
}

.orangeSpan {
  color: #ae582b;
}

.yellowSpan {
  color: #b4b005;
}

.toolTip {
  background-color: #dcdcdc;
  padding: 10px 15px 10px 15px;
  clip-path: inset(0 0 0 0 round 20px 20px 20px 0);
  z-index: 5;
}
.toolTip--error {
  background-color: #ae582b;
  color: #fff;
  padding: 10px 15px 10px 15px;
  clip-path: inset(0 0 0 0 round 20px 20px 20px 0);
  z-index: 5;
}
@media screen and (max-width: 430px) {
  .toolTip--error {
    font-size: 0.8rem;
    min-width: 150px;
  }
}
.toolTip--about1 {
  background-color: #1d7fd9;
  color: #fff;
  padding: 10px 15px 10px 15px;
  clip-path: inset(0 0 0 0 round 0px 20px 20px 20px);
  max-width: 400px;
  margin: 20px;
  z-index: 5;
}
@media screen and (max-width: 430px) {
  .toolTip--about1 {
    font-size: 0.8rem;
    min-width: 150px;
  }
}
.toolTip--about2 {
  background-color: #ae582b;
  color: #fff;
  padding: 10px 15px 10px 15px;
  clip-path: inset(0 0 0 0 round 0px 20px 20px 20px);
  max-width: 400px;
  margin: 20px;
  z-index: 5;
}
@media screen and (max-width: 430px) {
  .toolTip--about2 {
    font-size: 0.8rem;
    min-width: 150px;
  }
}
.toolTip--about3 {
  background-color: #0b7764;
  color: #fff;
  padding: 10px 15px 10px 15px;
  clip-path: inset(0 0 0 0 round 0px 20px 20px 20px);
  max-width: 400px;
  margin: 20px;
  z-index: 5;
}
@media screen and (max-width: 430px) {
  .toolTip--about3 {
    font-size: 0.8rem;
    min-width: 150px;
  }
}