@use "../../utils/variables/colors" as c;
@use "../../utils/variables/mixins" as m;

.projectsIntro {
  width: 100%;
  background-color: c.$third_grey;
  &__container {
    width: 1280px;
    margin: 0 auto;
    @include m.dashedBorder("left_right");
    background-color: c.$white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 104px;
    padding-top: 94px;
    padding-right: 76px;
    padding-bottom: 80px;
    gap: 86px;
    @media screen and (max-width: 1275px) {
      width: 100%;
    }
    @media screen and (max-width: 767px){
      padding: 50px 30px;
    }
    & p {
      width: 50%;
      @media screen and (max-width: 1275px){
        width: 100%;
        margin-top: 30px;
      }
    }
    & h1 {
      font-family: "Fira sans";
      font-size: 86px;
      font-weight: 700;
      display: flex;
      flex-direction: row;
      @media screen and (max-width: 1275px){
        line-height: normal;
        display: block;
      }
      @media screen and (max-width: 767px){
        font-size: 50px;
      }
      & span {
        font-size: 86px;
        font-weight: 700;
        color: c.$blue;
        @media screen and (max-width: 767px){
          font-size: 50px;
        }
      }
    }
  }
}
