@use "../../utils/variables/mixins" as m;
@use "../../utils/variables/colors" as c;

.loader {
  // height: 100vh;
  width: 20%;
  text-align: center;
  padding: 1em;
  display: flex;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  /*
    Set the color of the icon
  */
  & svg path,
  svg rect {
    fill: c.$blue;
  }
}
