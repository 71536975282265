.contactSection {
  border-image-width: 1px;
  border-image-slice: 1;
  border-image-repeat: round;
  border-image-source: url(../../assets/images/border_bottom.png);
  width: 100%;
}
.contactSection__container {
  width: 1280px;
  margin: 0 auto;
  border-image-width: 1px;
  border-image-slice: 1;
  border-image-repeat: round;
  border-image-source: url(../../assets/images/border_left_right.png);
  background-color: #fdfdfd;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 104px;
  padding-top: 63px;
  padding-right: 104px;
  padding-bottom: 80px;
  gap: 44px;
}
@media screen and (max-width: 1275px) {
  .contactSection__container {
    width: 100%;
  }
}
@media screen and (max-width: 767px) {
  .contactSection__container {
    padding: 70px 30px;
    gap: 0px;
  }
}
.contactSection__container h2 {
  font-family: "Fira mono";
  font-size: 21px;
  font-weight: 700;
  display: flex;
  flex-direction: row;
  text-transform: uppercase;
}
.contactSection__container h2 span {
  font-size: 21px;
  font-weight: 400;
  color: #1d7fd9;
  margin-left: 17px;
}
.contactSection__container__mailAndForm {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 30px;
}
.contactSection__container__mailAndForm--mail {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
}
.contactSection__container__mailAndForm--mail div {
  margin: auto;
  display: flex;
  flex-direction: row;
}
.contactSection__container__mailAndForm--mail img {
  width: 19px;
  margin-left: 20px;
}
.contactSection__container__mailAndForm--mail #contactMail {
  text-decoration: underline;
  color: #0b7764;
}
.contactSection__container__mailAndForm--form {
  width: 50%;
  align-items: flex-end;
}
@media screen and (max-width: 1275px) {
  .contactSection__container__mailAndForm--form {
    width: 80%;
  }
}
.contactSection__container__mailAndForm--form p {
  margin-bottom: 20px;
}
.contactSection__container__mailAndForm--form form {
  display: block;
  flex-direction: column;
}
.contactSection__container__mailAndForm--form form input {
  width: 100%;
  height: 40px;
  padding-left: 10px;
  border-radius: 5px;
  border: 1px solid #dcdcdc;
  margin-bottom: 15px;
}
.contactSection__container__mailAndForm--form form input::placeholder {
  color: #dcdcdc;
}
.contactSection__container__mailAndForm--form form label {
  font-size: 14px;
  margin-bottom: 10px;
}
.contactSection__container__mailAndForm--form form textarea {
  width: 100%;
  height: 150px;
  padding: 10px 10px;
  border: 1px solid #dcdcdc;
  border-radius: 5px;
  margin-bottom: 15px;
}
.contactSection__container__mailAndForm--form form textarea::placeholder {
  color: #dcdcdc;
}