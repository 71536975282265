@use "../../utils/variables/colors" as c;
@use "../../utils/variables/mixins" as m;

.landingSection {
  z-index: -10;
  height: calc(100vh - 127px);
  width: 100%;
  position: relative;
  @media screen and (max-width: 767px) {
    height: 100vh;
  }
}

.landingImgContainer {
  position: fixed;
  height: 100vh;
  width: 100%;
  overflow: hidden;
  @media screen and (max-width: 1275px) {
    width: 100vw;
  }
  &__titleContainer {
    position: absolute;
    cursor: crosshair;
    top: 45%;
    left: 50%;
    box-sizing: border-box;
    text-align: center;
    border: 1px solid c.$white;
    padding: 3.5rem 11rem;
    border-radius: 5px;
    z-index: -6;
    // background-color: rgba($color: c.$white, $alpha: 0.4);
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
    transition: all 0.5s ease-in-out;
    @media screen and (max-width: 1275px) {
      padding: 2.5rem 9rem;
    }
    &:hover {
      // stroke button animation
      // animation: gentleShake 1s ease-in-out  infinite;
      // animation: scaleDown 5s ease-in-out forwards;
      transform: translate(-50%, -50%) scale(0.95);
      opacity: 0.3;
    }
    &.clickedContainer {
      transform: translate(-50%, -50%) scale(1);
      border: 1px solid rgba($color: c.$white, $alpha: 1);
      // background-color: rgba($color: c.$white, $alpha: 0.4);
      animation: scaleUp 0.5s ease-in-out forwards;
    }
    &__title {
      position: absolute;
      top: 45%;
      left: 50%;
      transform: translate(-50%, -50%) scale(1);
      text-align: center;
      color: c.$white;
      font-size: 3rem;
      font-weight: 700;
      z-index: -7;
      @media screen and (max-width: 767px) {
        font-size: 2rem;
      }
      & span {
        color: rgba($color: c.$white, $alpha: 0.3);
      }
      &.clickedTitle {
        transform: translate(-50%, -50%) scale(1);
        opacity: 1;
        animation: scaleDown 2s ease-in-out forwards;
        & span {
          animation: spanFadeIn 0.3s ease-in-out forwards;
        }
      }
    }
  }

  & img {
    position: absolute;
    z-index: -9;
    width: 111%; // to adjust the image size relative to the speed of parallax
    height: 111%;
    object-fit: cover;
    object-position: center;
  }
}

.overlay {
  position: absolute;
  cursor: none;
  z-index: -8;
  background-image: radial-gradient(
    circle,
    hsl(266deg 23% 12%) 0%,
    hsl(281deg 27% 20%) 1%,
    hsl(295deg 33% 26%) 2%,
    hsl(310deg 38% 32%) 3%,
    hsl(325deg 45% 38%) 4%,
    hsl(340deg 51% 43%) 5%,
    hsl(344deg 59% 45%) 6%,
    hsl(348deg 68% 47%) 7%,
    hsl(352deg 77% 48%) 8%,
    hsl(356deg 88% 49%) 9%,
    hsl(0deg 100% 50%) 11%,
    hsl(14deg 93% 58%) 13%,
    hsl(28deg 84% 66%) 17%,
    hsl(42deg 70% 73%) 23%,
    hsl(56deg 47% 79%) 35%,
    hsl(70deg 8% 85%) 85%
  );
  // background-size: 400% 400%;
  mix-blend-mode: exclusion;
  // animation: gradient 8s ease-in-out infinite;
  animation: breathing 5s ease-in-out infinite;
  height: 230%;
  width: 230%;
  @media screen and (max-width: 767px) {
    animation: breathing 5s ease-in-out infinite;
    height: 120%;
    width: 100%;
  }
}

.clickedOverlay {
  position: absolute;
  cursor: default;
  z-index: -8;
  background-image: radial-gradient(
    circle,
    hsl(266deg 23% 12%) 0%,
    hsl(281deg 27% 20%) 1%,
    hsl(295deg 33% 26%) 2%,
    hsl(310deg 38% 32%) 3%,
    hsl(325deg 45% 38%) 4%,
    hsl(340deg 51% 43%) 5%,
    hsl(344deg 59% 45%) 6%,
    hsl(348deg 68% 47%) 7%,
    hsl(352deg 77% 48%) 8%,
    hsl(356deg 88% 49%) 9%,
    hsl(0deg 100% 50%) 11%,
    hsl(14deg 93% 58%) 13%,
    hsl(28deg 84% 66%) 17%,
    hsl(42deg 70% 73%) 23%,
    hsl(56deg 47% 79%) 35%,
    hsl(70deg 8% 85%) 85%
  );
  // background-size: 400% 400%;
  mix-blend-mode: exclusion;
  // animation: gradient 8s ease-in-out infinite;
  animation: overlayScaleUp 2s ease-in-out forwards;
  height: 230%;
  width: 230%;
  @media screen and (max-width: 767px) {
    animation: overlayScaleUp 2s ease-in-out forwards;
    height: 120%;
    width: 100%;
  }
}

#redo {
  position: fixed;
  top: calc(50% - 68px);
  left: 50%;
  transform: translate(-50%, -50%) scale(1);
  width: 80px;
  height: 80px;
  z-index: 1;
  cursor: pointer;
  pointer-events: fill;
  border: 1px solid c.$white;
  border-radius: 6px;
  padding: 20px;
  transition: all 0.3s ease-in-out;
  &:hover {
    transform: translate(-50%, -50%) scale(1.1);
  }
  @media screen and (max-width: 767px){
    top: 50%;
  }
}

.transitionMask {
  position: fixed;
  top: 0;
  left: 0;
  width: 1vw;
  height: calc(100vh - 127px);
  background-color: c.$white;
  z-index: 2;
  @media screen and (max-width: 767px) {
    height: 100vh;
  }
}

.transitionMask--animation {
  position: fixed;
  top: 0;
  left: 0;
  width: 1vw;
  height: calc(100vh - 127px);
  background-color: c.$white;
  z-index: 2;
  animation: transitionMaskAnimation 1s ease-in-out forwards;
  @media screen and (max-width: 767px) {
    height: 100vh;
  }
}

@keyframes transitionMaskAnimation {
  0% {
    left: 0;
    width: 1vw;
  }
  50% {
    left: 0;
    width: 100vw;
  }
  100% {
    left: 100vw;
    width: 0;
  }
}

@keyframes breathing {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes gentleShake {
  0% {
    transform: translate(-50%, -50%) scale(0.9);
  }
  33% {
    transform: translate(-52%, -50%) scale(0.9);
  }
  66% {
    transform: translate(-48%, -50%) scale(0.9);
  }
  100% {
    transform: translate(-50%, -50%) scale(0.9);
  }
}

@keyframes gradient {
  0% {
    background-position: 50% 0%;
  }
  25% {
    background-position: 100% 100%;
  }
  50% {
    background-position: 50% 100%;
  }
  75% {
    background-position: 0% 0%;
  }
  100% {
    background-position: 50% 0%;
  }
}

@keyframes pulsate-bck {
  0% {
    transform: translate(-50%, -50%) scale(1);
  }
  50% {
    transform: translate(-50%, -50%) scale(0.9);
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
  }
}

@keyframes overlayScaleUp {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(15);
  }
}

@keyframes spanFadeIn {
  0% {
    color: rgba($color: c.$white, $alpha: 0.3);
  }
  100% {
    color: rgba($color: c.$white, $alpha: 1);
  }
}

@keyframes scaleUp {
  0% {
    transform: translate(-50%, -50%) scale(0.95);
    border: 1px solid rgba($color: c.$white, $alpha: 1);
  }
  20% {
    transform: translate(-50%, -50%) scale(0.9);
    border: 1px solid rgba($color: c.$white, $alpha: 0.8);
  }
  100% {
    transform: translate(-50%, -50%) scale(3);
    border: 1px solid rgba($color: c.$white, $alpha: 0);
    cursor: default;
  }
}

@keyframes scaleDown {
  0% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
  }
  20% {
    transform: translate(-50%, -50%) scale(1.1);
    opacity: 1;
  }
  100% {
    transform: translate(-50%, -50%) scale(0.8);
    opacity: 0;
  }
}
